import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'


export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const data = this.state

    fetch('/.netlify/functions/send-contactus-request', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
       },
      body: JSON.stringify(data),
    })
    .then((res) => {
      if (res.status === 200){
        navigate(form.getAttribute('action'))
      } else if (res.status in [401, 500]){
        return {
          statusCode: res.status,
          body: res.json,
        };
      }else{
        return {
          statusCode: 400,
          body: "Request Failed.",
        };
      }
    })
    .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content" style={{width: '60vw', margin: '1em auto'}}>
              <h1>Contact</h1>

              <p>To reach out to me regarding anything relating to the content on this website, please send email addressed to <code className='contact-code'>contact@simarmannsingh.com</code>.</p>
              <p>For ease of access, this contact form can also be used, whatever you feel right.</p>
              To send encrypted emails for any sensitive topic, you can use <a href="https://keys.openpgp.org/search?q=contact@simarmannsingh.com" target='_blank'><b><u>this</u></b></a> public key to encrypt the mails.
              The key can be downloaded directly from 
              <a href="./../../../contact@simarmannsingh_public_key.asc" title="here" download><b><u> here</u></b></a>.

              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don't fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    Message
                  </label>
                  <div className="control">
                    <textarea
                      className="textarea"
                      name={'message'}
                      onChange={this.handleChange}
                      id={'message'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
